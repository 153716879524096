/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Ref: https://github.com/backstage/backstage/blob/master/plugins/tech-radar/src/components/RadarPage.tsx
import {
  Content,
  ContentHeader,
  Header,
  Page,
  SupportButton,
} from '@backstage/core-components';
import { Grid, Input, Link, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {
  TechRadarComponent,
  TechRadarComponentProps,
} from '@backstage-community/plugin-tech-radar';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(() => ({
  overflowXScroll: {
    overflowX: 'scroll',
  },
  editLink: {
    paddingLeft: '30px',
  },
}));

/**
 * Properties for {@link TechRadarPage}
 *
 * @public
 */
export interface WpeTechRadarPageProps extends TechRadarComponentProps {
  /**
   * Title
   */
  title: string;
  /**
   * Slack channel name
   */
  slackChannelName: string;
  /**
   * Slack channel link
   */
  slackChannelLink: string;
  /**
   * GitHub source code link
   */
  editLink: string;
}

/**
 * Main Page of Tech Radar
 *
 * @public
 */
export function WpeRadarPage(props: WpeTechRadarPageProps) {
  const {
    title,
    slackChannelName,
    slackChannelLink,
    editLink,
    ...componentProps
  } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('');

  return (
    <Page themeId="tool">
      <Header
        title={title}
        subtitle={
          <Typography variant="subtitle2" component="span">
            The {title} is our opinionated guide to software languages,
            frameworks, tools, platforms, and techniques. It helps control
            technical diversity to provide economies of scale, which reduces
            cost, specialized skills, security exposure, operations, and
            maintenance. It makes patterns more reusable and implementations
            more predictable.
          </Typography>
        }
      />
      <Content className={classes.overflowXScroll}>
        <ContentHeader title={`Suggestions/exceptions in ${slackChannelName}`}>
          <Input
            id="tech-radar-filter"
            type="search"
            placeholder="Filter"
            onChange={e => setSearchText(e.target.value)}
          />
          <Link target="_blank" href={editLink} className={classes.editLink}>
            <EditIcon />
          </Link>
          <SupportButton
            items={[
              {
                title:
                  'How to use the radar, rationale, ring definitions, and how to create your own:',
                icon: 'description',
                links: [
                  {
                    title: 'Technology Radar guidance',
                    url: '/docs/default/domain/architecture/Technology_Radar/',
                  },
                ],
              },
              {
                title: 'Make a PR for minor changes like typos',
                icon: 'github',
                links: [{ title: 'edit', url: editLink }],
              },
              {
                title:
                  'Reach out on Slack to make suggestions, ask questions, or request exceptions',
                icon: 'chat',
                links: [{ title: slackChannelName, url: slackChannelLink }],
              },
            ]}
          />
        </ContentHeader>
        <Grid container spacing={3} direction="row">
          <Grid item xs={12} sm={6} md={4}>
            <TechRadarComponent searchText={searchText} {...componentProps} />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
}
