import { useEntity } from '@backstage/plugin-catalog-react';
import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { IFrameContent } from '../IFrameContent';
import {
  iFrameRoutes,
  routeGCPSpendByProjectsFlywheel,
  routeGCPSpendByProjectsWPEngine,
  routeGCPSpendBySKUsFlywheel,
  routeGCPSpendBySKUsWPEngine,
} from '../../../../../packages/app/src/iFrameRoutes';
import { TabbedLayout } from '@backstage/core-components';

export interface EntityCostContentProps {}

export const JIRA_PROJECT_KEY_ANNOTATION = 'jira/project-key';
export const GITHUB_PROJECT_SLUG_ANNOTATION = 'github.com/project-slug';
export const googleCloudProjectIconName = 'googleCloudProject';
export const toolsProjectIconName = 'toolsProject';

export const isEntityCostContentAvailable = (entity: Entity | undefined) => {
  if (!entity) {
    return false;
  }
  const repo = entity?.metadata?.annotations?.[GITHUB_PROJECT_SLUG_ANNOTATION];

  // we only have cost dashboards for flywheel and wpengine
  if (
    !repo ||
    (!repo.startsWith('wpengine/') && !repo.startsWith('getflywheel/'))
  ) {
    return false;
  }

  // if we cannot meaningfully filter the cost dashboards, we should not show them, since the entity
  // page would be no better than the costs pages accessible from the main menu
  if (entity.kind === 'Group') {
    return Boolean(entity?.metadata.annotations?.[JIRA_PROJECT_KEY_ANNOTATION]);
  }
  const matchingLinks = entity?.metadata?.links?.filter(
    link =>
      link.icon === googleCloudProjectIconName ||
      link.icon === toolsProjectIconName,
  );
  return Boolean(matchingLinks && matchingLinks?.length > 0);
};

export function EntityCostContent(_props: EntityCostContentProps) {
  const { entity } = useEntity();
  const repo: string =
    entity?.metadata?.annotations?.[GITHUB_PROJECT_SLUG_ANNOTATION] ||
    'wpengine/';
  let costByProjectsIFrameRoute: string | unknown;
  let costBySKUsIFrameRoute: string | unknown;
  if (repo?.startsWith('getflywheel/')) {
    costByProjectsIFrameRoute = routeGCPSpendByProjectsFlywheel;
    costBySKUsIFrameRoute = routeGCPSpendBySKUsFlywheel;
  } else if (repo?.startsWith('wpengine/')) {
    costByProjectsIFrameRoute = routeGCPSpendByProjectsWPEngine;
    costBySKUsIFrameRoute = routeGCPSpendBySKUsWPEngine;
  } else {
    // isEntityCostContentAvailable should have protected this, but...
    return <></>;
  }

  const byProjectsIFrameProps = iFrameRoutes.find(
    iframe => iframe.route === costByProjectsIFrameRoute,
  );
  const bySKUsIFrameProps = iFrameRoutes.find(
    iframe => iframe.route === costBySKUsIFrameRoute,
  );
  let params: string | unknown;

  if (entity.kind === 'Group') {
    params = `Project+ID=&Project+Label+Jira=${entity.metadata.annotations?.[
      JIRA_PROJECT_KEY_ANNOTATION
    ].toLowerCase()}`;
  } else {
    const matchingLinks = entity?.metadata?.links?.filter(
      link =>
        link.icon === googleCloudProjectIconName ||
        link.icon === toolsProjectIconName,
    );
    const projectIDs = matchingLinks?.map(link =>
      link.title?.split(': ').length === 2
        ? link.title?.split(': ')[1]
        : link.url?.split('=')[1],
    );
    params = `Project+ID=${encodeURIComponent(projectIDs?.join(',') || '')}`;
  }

  return (
    <TabbedLayout>
      <TabbedLayout.Route
        path="/cost-by-projects"
        title="By Projects"
        data-testid="cost-by-projects"
        key="cost-by-projects"
      >
        <IFrameContent
          {...{
            iframe: {
              src: `${byProjectsIFrameProps?.src}?${params}`,
            },
            title: byProjectsIFrameProps?.title,
            externalUrl:
              byProjectsIFrameProps?.fromRef ||
              byProjectsIFrameProps?.src ||
              '',
          }}
        />
      </TabbedLayout.Route>
      <TabbedLayout.Route
        path="/cost-by-skus"
        title="By SKUs"
        data-testid="cost-by-skus"
        key="cost-by-skus"
      >
        <IFrameContent
          {...{
            iframe: {
              src: `${bySKUsIFrameProps?.src}?${params}`,
            },
            title: bySKUsIFrameProps?.title,
            externalUrl:
              byProjectsIFrameProps?.fromRef ||
              byProjectsIFrameProps?.src ||
              '',
          }}
        />
      </TabbedLayout.Route>
    </TabbedLayout>
  );
}
