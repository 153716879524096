import { rootRouteRef, viewTechDocRouteRef } from './routes';
import { AboutCardProps } from '@backstage/plugin-catalog';
import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { FunctionsListCardProps } from './components/FunctionsListCard';
import { CloudBuildContentProps } from './components/EntityCloudbuildGoldenSignalsContent';
import { EntityIFrameCardsProps } from './components/EntityIFrameCards';
import { GoldenSignalsDashboardsProps } from './components/GoldenSignalsDashboards';
import { EntityCostContentProps } from './components/EntityCostContent';

export const wpeCatalogPlugin = createPlugin({
  id: 'wpe-catalog',
  routes: {
    root: rootRouteRef,
  },
  externalRoutes: {
    viewTechDoc: viewTechDocRouteRef,
  },
});

/** @public */
export const WpeEntityGroupAboutCard: (props: AboutCardProps) => JSX.Element =
  wpeCatalogPlugin.provide(
    createComponentExtension({
      name: 'WpeGroupAboutCard',
      component: {
        lazy: () =>
          import('./components/WpeGroupAboutCard').then(
            m => m.WpeGroupAboutCard,
          ),
      },
    }),
  );

/** @public */
export const WpeEntityFunctionsListCard: (
  props: FunctionsListCardProps,
) => JSX.Element = wpeCatalogPlugin.provide(
  createComponentExtension({
    name: 'WpeEntityFunctionsListCard',
    component: {
      lazy: () =>
        import('./components/FunctionsListCard').then(m => m.FunctionsListCard),
    },
  }),
);

/** @public */
export const WpeEntityLinksPanel: () => JSX.Element = wpeCatalogPlugin.provide(
  createComponentExtension({
    name: 'WpeEntityLinksPanel',
    component: {
      lazy: () => import('./components/LinksPanel').then(m => m.LinksPanel),
    },
  }),
);

/** @public */
export const WpeEntityCloudbuildGoldenSignalsContent: (
  props: CloudBuildContentProps,
) => JSX.Element = wpeCatalogPlugin.provide(
  createComponentExtension({
    name: 'WpeEntityCloudbuildGoldenSignalsContent',
    component: {
      lazy: () =>
        import('./components/EntityCloudbuildGoldenSignalsContent').then(
          m => m.EntityCloudbuildGoldenSignalsContent,
        ),
    },
  }),
);

export { isCloudbuildGoldenSignalsAvailable } from './components/EntityCloudbuildGoldenSignalsContent';

export const WpeIFramePage = wpeCatalogPlugin.provide(
  createRoutableExtension({
    mountPoint: rootRouteRef,
    name: 'WpeIFramePage',
    component: () => import('./components/IFramePage').then(m => m.IFramePage),
  }),
);

/** @public */
export const WpeEntityIFrameCards: (
  props: EntityIFrameCardsProps,
) => JSX.Element = wpeCatalogPlugin.provide(
  createComponentExtension({
    name: 'WpeEntityIFrameCards',
    component: {
      lazy: () =>
        import('./components/EntityIFrameCards').then(m => m.EntityIFrameCards),
    },
  }),
);

/** @public */
export const WpeGoldenSignalsDashboards: (
  props: GoldenSignalsDashboardsProps,
) => JSX.Element = wpeCatalogPlugin.provide(
  createComponentExtension({
    name: 'WpeGoldenSignalsDashboards',
    component: {
      lazy: () =>
        import('./components/GoldenSignalsDashboards').then(
          m => m.GoldenSignalsDashboards,
        ),
    },
  }),
);

export const WpeIFrameContent = wpeCatalogPlugin.provide(
  createRoutableExtension({
    name: 'WpeIFrameContent',
    component: () =>
      import('./components/IFrameContent').then(m => m.IFrameContent),
    mountPoint: rootRouteRef,
  }),
);

export { hasGoldenSignalsDashboards } from './components/GoldenSignalsDashboards';

/** @public */
export const WpeEntityCostContent: (
  props: EntityCostContentProps,
) => JSX.Element = wpeCatalogPlugin.provide(
  createComponentExtension({
    name: 'WpeEntityCostContent',
    component: {
      lazy: () =>
        import('./components/EntityCostContent').then(m => m.EntityCostContent),
    },
  }),
);

export { isEntityCostContentAvailable } from './components/EntityCostContent';
