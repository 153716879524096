import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { techdocsPlugin, TechDocsReaderPage } from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import { oktaAuthApiRef } from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';
import {
  ALL_RELATION_PAIRS,
  RelationPairs,
} from '@backstage/plugin-catalog-graph';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { wpeCatalogPlugin } from 'backstage-plugin-wpe-catalog';
import { TechDocsCustomHome } from '@backstage/plugin-techdocs';
import { techDocsTabsConfig } from './techDocsTabs';
import { wpeRelationPairs } from './components/catalog/EntityPage';
import { WpeRadarPage } from './lib/wpeTechRadars/wpeRadarPage';

import GoogleCloudProjectIcon from '@material-ui/icons/CloudCircle';
import AmazonCloudProjectIcon from '@material-ui/icons/CloudCircleOutlined';
import AzureCloudProjectIcon from '@material-ui/icons/CloudCircleTwoTone';
import MeshProjectIcon from '@material-ui/icons/SettingsSystemDaydream';
import KubernetesProjectIcon from '@material-ui/icons/SettingsSystemDaydream';
import ToolsProjectIcon from '@material-ui/icons/Build';
import ConfigIcon from '@material-ui/icons/SettingsApplications';
import ApiBaseIcon from '@material-ui/icons/Extension';
import WebsiteIcon from '@material-ui/icons/Web';
import ObservabilityIcon from '@material-ui/icons/Visibility';
import JiraIcon from '@material-ui/icons/BugReport';
import PubSubIcon from '@material-ui/icons/PlayForWork';
import ToolIcon from '@material-ui/icons/OpenInBrowser';
import GoldenSignalsIcon from '@material-ui/icons/TrendingUp';
import { allFunctionalRoles } from './lib/WpeOrg';
import { iFrameRoute, iFrameRoutes } from './iFrameRoutes';

const functionalRelations: RelationPairs = Array.from(allFunctionalRoles, k => [
  k,
  `${k} function for`,
]);

const app = createApp({
  apis,
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'okta-auth-provider',
            title: 'Okta',
            message: 'Sign in using Okta',
            apiRef: oktaAuthApiRef,
          }}
          title="Select a sign-in method"
          align="center"
        />
      );
    },
  },
  icons: {
    googleCloudProject: GoogleCloudProjectIcon,
    amazonCloudProject: AmazonCloudProjectIcon,
    azureCloudProject: AzureCloudProjectIcon,
    meshProject: MeshProjectIcon,
    kubernetesProject: KubernetesProjectIcon,
    toolsProject: ToolsProjectIcon,
    config: ConfigIcon,
    apiBase: ApiBaseIcon,
    website: WebsiteIcon,
    observability: ObservabilityIcon,
    jira: JiraIcon,
    pubsub: PubSubIcon,
    tool: ToolIcon,
    goldenSignals: GoldenSignalsIcon,
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    //
    if (process.env.NODE_ENV === 'development') {
      bind(apiDocsPlugin.externalRoutes, {
        registerApi: catalogImportPlugin.routes.importPage,
      });
      bind(scaffolderPlugin.externalRoutes, {
        registerComponent: catalogImportPlugin.routes.importPage,
        viewTechDoc: techdocsPlugin.routes.docRoot,
      });
    }
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(wpeCatalogPlugin.externalRoutes, {
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/docs"
      element={<TechDocsCustomHome tabsConfig={techDocsTabsConfig} />}
    />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <Mermaid
          darkConfig={{
            theme: 'dark',
            themeVariables: { lineColor: 'currentColor' },
          }}
          lightConfig={{
            theme: 'forest',
            themeVariables: { lineColor: 'currentColor' },
          }}
        />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={
        <WpeRadarPage
          title="P&E Technology Radar"
          slackChannelName="#architecture"
          slackChannelLink="https://wpengine.slack.com/archives/CFGADDBT5"
          editLink="https://github.com/wpengine/backstage/edit/main/src/packages/app/src/lib/wpeTechRadars/wpeTechRadarEntries.ts"
          id="wpengine-tech-radar"
          width={2300}
          height={1000}
        />
      }
    />
    <Route
      path="/portal-tech-radar"
      element={
        <WpeRadarPage
          title="User Portal Technology Radar"
          slackChannelName="#portal-devs"
          slackChannelLink="https://wpengine.slack.com/archives/GEJDN9RC1"
          editLink="https://github.com/wpengine/backstage/edit/main/src/packages/app/src/lib/wpeTechRadars/portalTechRadarEntries.ts"
          id="user-portal-tech-radar"
          width={2300}
          height={1000}
        />
      }
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    // see https://github.com/backstage/backstage/issues/8896
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          relationPairs={[
            ...ALL_RELATION_PAIRS,
            ...functionalRelations,
            ...wpeRelationPairs,
          ]}
        />
      }
    />
    {iFrameRoutes.map((iframe, index) => {
      return iFrameRoute({
        key: `iframe=${index}`,
        path: iframe.route,
        src: iframe.src,
        title: iframe.title,
        fromRef: iframe.fromRef,
      });
    })}
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
