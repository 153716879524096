import { useEntity } from '@backstage/plugin-catalog-react';
import React from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { determineIFrameError } from '../../utils';
import { IFrameContent } from '../IFrameContent';
import { TabbedLayout } from '@backstage/core-components';
import { Link } from '@backstage/core-components';
import { Entity } from '@backstage/catalog-model';
import { Typography } from '@material-ui/core';
import {
  EntityCloudbuildGoldenSignalsContent,
  isCloudbuildGoldenSignalsAvailable,
} from '../EntityCloudbuildGoldenSignalsContent';

export const goldenSignalsIframeType = 'golden-signals-iframe';
export const goldenSignalsLinkType = 'golden-signals-link';

function kebabCase(str: string) {
  return str
    .replace(/([A-Z])([A-Z])/g, '$1-$2')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
}

export const hasGoldenSignalsDashboards = (
  entity: Entity | undefined,
): boolean => {
  const matchingLinks = entity?.metadata?.links?.filter(
    link =>
      link.type === goldenSignalsIframeType ||
      link.type === goldenSignalsLinkType,
  );
  return Boolean(
    (matchingLinks && matchingLinks?.length > 0) ||
      isCloudbuildGoldenSignalsAvailable(entity),
  );
};

export interface GoldenSignalsDashboardsProps {}

export function GoldenSignalsDashboards(_props: GoldenSignalsDashboardsProps) {
  const { entity } = useEntity();
  const links = entity?.metadata?.links;
  const configApi = useApi(configApiRef);
  const allowList = configApi.getOptionalStringArray('iframe.allowList');

  return (
    <TabbedLayout>
      {links
        ?.filter(
          link =>
            link.type === goldenSignalsIframeType ||
            link.type === goldenSignalsLinkType,
        )
        .map((link, index) => {
          const { url, title } = link;
          const safeTitle = title || 'Error';
          const route = `/${kebabCase(safeTitle)}`;

          if (link.type === goldenSignalsIframeType) {
            const error = determineIFrameError(allowList, url, title);
            if (error !== '') {
              return (
                <TabbedLayout.Route
                  path={route}
                  title={safeTitle}
                  data-testid={`golden-signals-${index}`}
                  key={`golden-signals-${index}`}
                >
                  <Typography>{error}</Typography>
                </TabbedLayout.Route>
              );
            }
            return (
              <TabbedLayout.Route
                path={route}
                title={safeTitle}
                data-testid={`golden-signals-${index}`}
                key={`golden-signals-${index}`}
              >
                <IFrameContent
                  {...{
                    iframe: {
                      src: url,
                    },
                    title: title,
                  }}
                />
              </TabbedLayout.Route>
            );
          }
          return (
            <TabbedLayout.Route
              path={route}
              title={safeTitle}
              data-testid={`golden-signals-${index}`}
              key={`golden-signals-${index}`}
            >
              <Typography>
                <Link to={url}>Click here</Link> to continue to the {title}.
              </Typography>
            </TabbedLayout.Route>
          );
        })}
      {isCloudbuildGoldenSignalsAvailable(entity) && (
        <TabbedLayout.Route
          path="/cloudbuild-deploys"
          title="Deploys"
          data-testid="cloudbuild-deploys"
          key="cloudbuild-deploys"
        >
          <EntityCloudbuildGoldenSignalsContent />
        </TabbedLayout.Route>
      )}
      <TabbedLayout.Route path="/" title="Help" data-testid="help" key="help">
        <Typography variant="body2" component="span">
          To add Golden Signals such as KPIs, add links to the entity definition
          in your <code>catalog-info.yaml</code> file.
          <ul>
            <li>
              Links of type <code>golden-signals-iframe</code>
              embed reports from Looker or Looker Studio. Their URL should be a
              private embed link to the report.
            </li>
            <li>
              Links of type <code>golden-signals-links</code> navigate to apps
              outside Backstage, supporting cases where dashboards cannot be
              embedded, such as DataDog.
            </li>
          </ul>
          <p>
            For more, see{' '}
            <Link to="/docs/default/resource/backstage-catalog/registering-catalog-entities/">
              the documentation
            </Link>
          </p>
        </Typography>
      </TabbedLayout.Route>
    </TabbedLayout>
  );
}
