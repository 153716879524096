// Ref: https://github.com/RoadieHQ/roadie-backstage-plugins/blob/main/plugins/frontend/backstage-plugin-iframe/src/components/IFrameContent.tsx
import React from 'react';
import {
  Content,
  ContentHeader,
  Link,
  SupportButton,
  WarningPanel,
} from '@backstage/core-components';
import { IFrameContentProps } from '../types';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { ErrorComponent } from '../ErrorComponent';
import { determineIFrameError } from '../../utils';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  externalLink: {
    textAlign: 'right',
  },
});

export const IFrameContent = (props: IFrameContentProps) => {
  const { title, iframe, description, externalUrl } = props;
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const allowList = configApi.getOptionalStringArray('iframe.allowList');
  const errorMessage = determineIFrameError(allowList, iframe?.src, title);

  if (errorMessage !== '') {
    return <ErrorComponent {...{ errorMessage }} />;
  }

  return (
    <Content>
      <ContentHeader
        title={
          title ||
          'Custom content for adding IFrame component(s). You can modify the title field with the props.'
        }
      >
        <SupportButton
          title="To learn more about this mechanism, use the links below."
          items={[
            {
              title: 'Embedding Golden Signals',
              icon: 'description',
              links: [
                {
                  title: 'General guidance',
                  url: '/docs/default/resource/backstage-catalog/golden-signals/',
                },
                {
                  title: 'Top-level Costs and Golden Signals menus',
                  url: '/docs/default/resource/backstage-catalog/golden-signals/#top-level-golden-signals-menu-navigation-to-pages',
                },
                {
                  title: 'Adding Golden Signals to a specific entity',
                  url: '/docs/default/resource/backstage-catalog/golden-signals/#adding-golden-signals-such-as-kpis-to-a-specific-entity',
                },
              ],
            },
            {
              title: 'Understanding Costs via Backstage',
              icon: 'description',
              links: [
                {
                  title: 'Top-level Costs menus',
                  url: '/docs/default/resource/backstage-catalog/golden-signals/#top-level-golden-signals-menu-navigation-to-pages',
                },
                {
                  title: 'Supporting costs on entities',
                  url: '/docs/default/resource/backstage-catalog/costs/#supporting-costs-on-entities',
                },
              ],
            },
            {
              title: 'Slack',
              icon: 'chat',
              links: [
                {
                  title: '#backstage-help',
                  url: 'https://wpengine.slack.com/archives/C04ECKEM14J',
                },
              ],
            },
          ]}
        />
      </ContentHeader>
      {iframe.title && (
        <Typography variant="h2" component="span">
          {iframe.title}
        </Typography>
      )}
      <Typography variant="body2" component="span">
        {description && <p>{description}</p>}
        {iframe.src.startsWith('https://wpengine.looker.com/') && (
          <WarningPanel
            severity="info"
            title="You may need to authenticate externally to view the content"
          >
            If you see a "You are not authenticated to view this page" error:
            <ul>
              <li>
                click{' '}
                <Link
                  to="https://wpengine.okta.com/home/looker/0oaly213oglNLr56J0x7/69259"
                  target="_blank"
                  rel="noopener"
                >
                  here
                </Link>{' '}
                or the Okta tile from your Okta home page
              </li>
              <li>return to Backstage and refresh this page</li>
              <li>
                if you still get the "You are not authenticated" error, click
                the link below to open a new browser tab, where you can
                authenticate and access the content
              </li>
            </ul>
          </WarningPanel>
        )}
        <p className={classes.externalLink}>
          Open in a{' '}
          <Link to={externalUrl || iframe.src} target="_blank" rel="noopener">
            new tab
          </Link>{' '}
        </p>
      </Typography>
      <iframe
        src={iframe.src}
        height={iframe.height || '1200'}
        width={iframe.width || '100%'}
        title={iframe.title}
        data-testid="iframe"
      />
    </Content>
  );
};
