// https://backstage.io/storybook/?path=/story/plugins-home-templates--default-template
import {
  HomePageToolkit,
  HomePageStarredEntities,
  ClockConfig,
  HeaderWorldClock,
  ComponentAccordion,
} from '@backstage/plugin-home';
import { Content, Page, SupportButton } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import TechdocsIcon from '@material-ui/icons/LibraryBooks';
import SystemIcon from '@material-ui/icons/Category';
import { Header } from '@backstage/core-components';
import WebsiteIcon from '@material-ui/icons/Web';
import GroupIcon from '@material-ui/icons/People';
import TrainingIcon from '@material-ui/icons/School';
import ApiIcon from '@material-ui/icons/Extension';
import ComponentIcon from '@material-ui/icons/Memory';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import ChatIcon from '@material-ui/icons/Chat';
import ForumIcon from '@material-ui/icons/Forum';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: '50px',
    margin: 'auto',
    '& [class^="MuiInputBase-root"]': {
      borderRadius: '50px',
      padding: '8px 14px',
    },
  },
}));

export const HomePage = () => {
  const classes = useStyles();

  const clockConfigs: ClockConfig[] = [
    {
      label: 'Austin',
      timeZone: 'US/Central',
    },
    {
      label: 'Omaha',
      timeZone: 'US/Central',
    },
    {
      label: 'UTC',
      timeZone: 'UTC',
    },
    {
      label: 'London',
      timeZone: 'Europe/London',
    },
    {
      label: 'Limerick',
      timeZone: 'Europe/London',
    },
    {
      label: 'Kraków',
      timeZone: 'Europe/Belgrade',
    },
    {
      label: 'Brisbane',
      timeZone: 'Australia/Brisbane',
    },
  ];

  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const backstageEssentials = [
    {
      url: '/docs/default/resource/backstage-catalog',
      label: 'Backstage guidance',
      icon: <TechdocsIcon />,
    },
    {
      url: 'https://www.myworkday.com/wpengine/learning/course/8356624f7d451001a31a5a206c8c0000?type=9882927d138b100019b928e75843018d',
      label: 'Backstage training',
      icon: <TrainingIcon />,
    },
    {
      url: '/catalog?filters%5Bkind%5D=group&filters%5Buser%5D=all',
      label: 'P&E groups & teams (Backstage)',
      icon: <GroupIcon />,
    },
    {
      url: '/api-docs?filters%5Bkind%5D=api&filters%5Buser%5D=all&filters%5Btags%5D=cross-domain-api',
      label: 'Cross-domain APIs',
      icon: <ApiIcon />,
    },
    {
      url: '/api-docs?filters%5Bkind%5D=api&filters%5Buser%5D=all',
      label: 'All APIs',
      icon: <ApiIcon />,
    },
    {
      url: '/catalog?filters%5Bkind%5D=system&filters%5Buser%5D=all',
      label: 'Systems',
      icon: <SystemIcon />,
    },
    {
      url: '/catalog?filters%5Bkind%5D=component&filters%5Btype%5D=service&filters%5Buser%5D=all',
      label: 'Services',
      icon: <ComponentIcon />,
    },
  ];

  const docs = [
    {
      url: '/docs/default/group/product-and-engineering',
      label: 'P&E docs',
      icon: <TechdocsIcon />,
    },
    {
      url: '/docs/default/group/product-and-engineering/glossaries/',
      label: 'Glossaries',
      icon: <TechdocsIcon />,
    },
    {
      url: 'https://wpengine.atlassian.net/wiki/spaces/ProgramMgmt/pages/3840475190/Success+of+our+Customers',
      label: 'Success of our customers',
      icon: <TechdocsIcon />,
    },
    {
      url: '/docs/default/Domain/apis',
      label: 'Building APIs',
      icon: <ApiIcon />,
    },
    {
      url: '/docs/default/system/catalyst-software-delivery-platform',
      label: 'Software Delivery Platform',
      icon: <TechdocsIcon />,
    },
    {
      url: 'https://docs.google.com/spreadsheets/d/1K4LO45l-eO8kMwh2GE3eK0GpJcotb02oi24A0jcx2vI/edit',
      label: 'P&E groups & teams (Sheet)',
      icon: <GroupIcon />,
    },
    {
      url: 'https://docs.google.com/spreadsheets/d/10m-CWahAFZ2Q7-1Qh0wZfiaCFz0ZC__Ls-IPtznRtVc/edit',
      label: 'System ownership',
      icon: <GroupIcon />,
    },
    {
      url: 'https://docs.google.com/spreadsheets/d/1j-Z9sphWqKJ_pFvoNaFNDd-P4mO-IL9dcLOMAPWL9yI/edit',
      label: 'Portal ownership',
      icon: <GroupIcon />,
    },
    {
      url: 'https://enginet.wpengine.com/resources/security-policies/',
      label: 'Security policies',
      icon: <TechdocsIcon />,
    },
  ];

  const internal = [
    {
      url: '/catalog/default/system/user-portal',
      label: 'User Portal',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://app.getflywheel.com/',
      label: 'Flywheel app',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://lookerstudio.google.com/u/0/reporting/98c444f1-393e-483e-a1a9-12e939768ff9',
      label: 'GCP Cost Dashboard',
      icon: <MoneyIcon />,
    },
    {
      url: 'https://overdrive.wpengine.io/',
      label: 'Overdrive (VPN)',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://api.prd.sdp.wpesvc.net/signup-scorecard-ui/',
      label: 'Signup scorecard',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://privatebin.wpesvc.net/',
      label: 'PrivateBin',
      icon: <WebsiteIcon />,
    },
  ];

  const customerFeedbackTools = [
    {
      url: 'https://wpengine-product.canny.io/',
      label: 'Canny',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://wpengine.atlassian.net/wiki/spaces/PRODUCT/pages/3477143665',
      label: 'Canny Instructions',
      icon: <TechdocsIcon />,
    },
    {
      url: 'https://www.trustpilot.com/review/wpengine.com',
      label: 'Trustpilot',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://wpengine.dovetail.com/home',
      label: 'Dovetail',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://wpengine.atlassian.net/jira/software/c/projects/IN/boards/1133',
      label: 'Incoming Project (internal feedback and bug reporting) ',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://wpengine.slack.com/archives/C01F3CG8FEV',
      label: '#uxr-session-summaries',
      icon: <ChatIcon />,
    },
  ]

  const customerForums = [
    {
      url: 'http://wpengineagencies.slack.com/',
      label: 'WP Engine Agencies Slack',
      icon: <ChatIcon />,
    },
    {
      url: 'https://community.localwp.com/',
      label: 'Local Community',
      icon: <ForumIcon />,
    },
    {
      url: 'https://twitter.com/wpengine',
      label: 'X: WP Engine',
      icon: <TwitterIcon />,
    },
    {
      url: 'https://twitter.com/wp_acf',
      label: 'X: Advanced Custom Fields',
      icon: <TwitterIcon />,
    },
    {
      url: 'https://twitter.com/wpmigratedbpro',
      label: 'X: WP Migrate',
      icon: <TwitterIcon />,
    },
    {
      url: 'https://twitter.com/wpoffloadmedia',
      label: 'X: WP Offload Media',
      icon: <TwitterIcon />,
    },
    {
      url: 'https://twitter.com/wpoffloadses',
      label: 'X: WP Offload SES',
      icon: <TwitterIcon />,
    },
  ];

  const learnWordPress = [
    {
      url: 'https://wpengine.com/blog/decode-2023-sessions-now-available/',
      label: 'DE{CODE}',
      icon: <TrainingIcon />,
    },
    {
      url: 'https://central.wordcamp.org/',
      label: 'WordCamp',
      icon: <TrainingIcon />,
    },
    {
      url: 'https://wordpress.org/state-of-the-word/#:~:text=State%20of%20the%20Word',
      label: 'WordPress State of Word',
      icon: <TrainingIcon />,
    },
    {
      url: 'https://wpengine.com/blog/top-conferences-for-wordpress-professionals-2024/',
      label: 'Online Conferences',
      icon: <TrainingIcon />,
    },
    {
      url: 'https://wpengine.com/',
      label: 'wpengine.com',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://deliciousbrains.com/online-conferences-for-wordpress-devs-in-2024/',
      label: 'WordPress Conferences',
      icon: <TrainingIcon />,
    },
    {
      url: 'https://wpengine.com/resources/#resource-topic=1480',
      label: 'Webinars',
      icon: <TrainingIcon />,
    },
    {
      url: 'https://wpengine.atlassian.net/wiki/spaces/PE',
      label: 'Confluence',
      icon: <WebsiteIcon />,
    },
  ];

  const tools = [
    {
      url: 'https://github.com/wpengine',
      label: 'GitHub',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://repos.wpesvc.net',
      label: 'Create Repo',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://wpengine.atlassian.net/jira',
      label: 'JIRA',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://wpengine.atlassian.net/wiki',
      label: 'Confluence',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://console.cloud.google.com/home',
      label: 'GCP',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://wpengine.okta.com/home/amazon_aws/0oamifav2dBHoHSVC0x7/272',
      label: 'AWS',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://wpengine.okta.com/home/realtime_board/0oas1bim9hZL7qBG60x7/24467',
      label: 'Miro',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://wpengine.okta.com/home/pagerduty/0oaj2uwbpdjmyVNsG0x7/3107',
      label: 'PagerDuty',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://wpengine.okta.com/home/lucidchart/0oa3xlvczhBiJ0uVc0x7/2655',
      label: 'Lucidchart',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://wpengine.okta.com/home/pendo/0oaxait72kEu7tAEc0x7/alneesf033U8t6Ir40x7',
      label: 'Pendo',
      icon: <WebsiteIcon />,
    },
    {
      url: 'https://sonar.wpengine.io',
      label: 'SonarQube',
      icon: <WebsiteIcon />,
    },
  ];

  const ExpandedComponentAccordion = (props: any) => (
    <ComponentAccordion expanded {...props} />
  );

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title="WP Engine" pageTitleOverride="Home">
          <>
            <HeaderWorldClock
              clockConfigs={clockConfigs}
              customTimeFormat={timeFormat}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <SupportButton>
              These resources will get you up and running with the Backstage app, or learn how to onboard your
              team's entities.
            </SupportButton>
          </>
        </Header>
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            {/* <HomePageCompanyLogo
              className={container}
              logo={<TemplateBackstageLogo classes={{ svg, path }} />}
            /> */}
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={classes}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  title="Backstage essentials"
                  tools={backstageEssentials}
                  Renderer={ExpandedComponentAccordion}
                />
                <HomePageToolkit
                  title="Docs"
                  tools={docs}
                  Renderer={ExpandedComponentAccordion}
                />
                <HomePageToolkit
                  title="Customer feedback tools"
                  tools={customerFeedbackTools}
                  Renderer={ExpandedComponentAccordion}
                />
                <HomePageToolkit
                  title="Internal sites"
                  tools={internal}
                  Renderer={ComponentAccordion}
                />
                <HomePageToolkit
                  title="Tools"
                  tools={tools}
                  Renderer={ComponentAccordion}
                />
                <HomePageToolkit
                  title="Customer forums"
                  tools={customerForums}
                  Renderer={ComponentAccordion}
                />
                <HomePageToolkit
                  title="Learn WP Engine Product and WordPress"
                  tools={learnWordPress}
                  Renderer={ComponentAccordion}
                />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
