import React from 'react';
import { Route } from 'react-router-dom';
import { WpeIFramePage } from 'backstage-plugin-wpe-catalog/src/plugin';

const siPerformanceGoldenSignalsMenuTitle =
  'SI 1: Deliver a More Performant Platform';
const siCustomerFrictionGoldenSignalsMenuTitle =
  'SI 3: Address Top Customer Friction Points';
const siUBSGoldenSignalsMenuTitle = 'SI 4: Systems Transformation: UBS';

const pillarFoundationalinnovationGoldenSignalsMenuTitle =
  'Pillar 1: Foundational Innovation';
const pillarSpecializedProductsGoldenSignalsMenuTitle =
  'Pillar 3: Specialized Products';

export const routeGCPSpendByProjectsWPEngine =
  '/gcp-spend-by-projects-wpengine';
export const routeGCPSpendByProjectsFlywheel =
  '/gcp-spend-by-projects-flywheel';
export const routeGCPSpendBySKUsWPEngine = '/gcp-spend-by-skus-wpengine';
export const routeGCPSpendBySKUsFlywheel = '/gcp-spend-by-skus-flywheel';

export enum iFrameMenu {
  NONE = '',
  COST = 'Cost',
  GOLDEN_SIGNALS = 'Golden Signals',
}

export type IFrameProps = {
  route: string; // the route / path of the page within the Backstage app. No spaces, in kebab-case
  src: string; // the src URL of the iFrame; the embed link for the report. See warnings in docs/iframes.md about needing authentication and authorization
  title: string; // the page title
  menu: iFrameMenu; // which menu, if any, that allows navigation to the page
  menuTitle?: string; // the primary title within the menu
  menuSubtitle?: string; // the secondary title within the menu, if needed
  fromRef?: string; // the source dashboard from which the embed link was obtained.
  contact: string; // the name of the group which requested this Costs or Golden Signal menu item. Reach our to them for questions about the iFrame's lifecycle.
};

// Global iFrame routes, like elements that are navigated to from the menu
export const iFrameRoutes: IFrameProps[] = [
  /*
   * Cost menu
   */
  {
    route: routeGCPSpendByProjectsWPEngine,
    src: 'https://wpengine.looker.com/embed/dashboards/2430',
    title: 'GCP Spend by Projects (WP Engine)',
    menu: iFrameMenu.COST,
    menuTitle: 'WP Engine GCP Spend by Projects',
    // menuSubtitle:,
    fromRef: 'https://wpengine.looker.com/dashboards/2430',
    contact: 'catalyst', // lego and Candace Tribble own the looker report, but Catalyst will manage this menu
  },
  {
    route: routeGCPSpendByProjectsFlywheel,
    src: 'https://wpengine.looker.com/embed/dashboards/2447',
    title: 'GCP Spend by Projects (Flywheel)',
    menu: iFrameMenu.COST,
    menuTitle: 'Flywheel GCP Spend by Projects',
    // menuSubtitle:,
    fromRef: 'https://wpengine.looker.com/dashboards/2447',
    contact: 'catalyst', // lego and Candace Tribble own the looker report, but Catalyst will manage this menu
  },
  {
    route: routeGCPSpendBySKUsWPEngine,
    src: 'https://wpengine.looker.com/embed/dashboards/2946',
    title: 'GCP Spend by SKUs (WP Engine)',
    menu: iFrameMenu.COST,
    menuTitle: 'WP Engine GCP Spend by SKUs',
    // menuSubtitle:,
    fromRef: 'https://wpengine.looker.com/dashboards/2946',
    contact: 'catalyst', // lego and Candace Tribble own the looker report, but Catalyst will manage this menu
  },
  {
    route: routeGCPSpendBySKUsFlywheel,
    src: 'https://wpengine.looker.com/embed/dashboards/2945',
    title: 'GCP Spend by SKUs (Flywheel)',
    menu: iFrameMenu.COST,
    menuTitle: 'Flywheel GCP Spend by SKUs',
    // menuSubtitle:,
    fromRef: 'https://wpengine.looker.com/dashboards/2945',
    contact: 'catalyst', // lego and Candace Tribble own the looker report, but Catalyst will manage this menu
  },
  // We have been asked to deprecate the GCP Cost Management Dashboard,'https://lookerstudio.google.com/u/0/reporting/98c444f1-393e-483e-a1a9-12e939768ff9/page/NCcPD',

  /*
   * Golden Signals menu
   */
  {
    route: '/web-vitals-in-production',
    src: 'https://wpengine.looker.com/embed/dashboards/1269',
    title: 'Web Vitals in Production',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    menuTitle: siPerformanceGoldenSignalsMenuTitle,
    menuSubtitle: 'Web Vitals in Production',
    fromRef: 'https://wpengine.looker.com/dashboards/1269',
    contact: 'platform-insights',
  },
  {
    route: '/cohort-latency-sli-latency-7-day-rolling-kpi',
    src: 'https://wpengine.looker.com/embed/dashboards/2534',
    title: 'Cohort Latency SLI: Latency 7 Day Rolling KPI',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    menuTitle: siPerformanceGoldenSignalsMenuTitle,
    menuSubtitle: 'Latency SLO',
    fromRef: 'https://wpengine.looker.com/dashboards/2534',
    contact: 'platform-insights',
  },
  {
    route: '/cohort-latency-sli-cohort-latency-slo',
    src: 'https://wpengine.looker.com/embed/dashboards/2179',
    title: 'Cohort Latency SLI: Cohort Latency SLO',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    menuTitle: siPerformanceGoldenSignalsMenuTitle,
    menuSubtitle: 'Latency 7 Day Rolling KPI',
    fromRef: 'https://wpengine.looker.com/dashboards/2179',
    contact: 'platform-insights',
  },
  {
    route: '/cx-performance',
    src: 'https://wpengine.looker.com/embed/dashboards/3264',
    title: 'CX Performance',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    menuTitle: siCustomerFrictionGoldenSignalsMenuTitle,
    menuSubtitle: 'CX Performance Dashboard',
    fromRef: 'https://wpengine.looker.com/dashboards/3264',
    contact: 'catalyst', // the report is owned by CX, but Catalyst will manage this menu
  },
  {
    route: '/bq-logcrunch-macro-indicators',
    src: 'https://lookerstudio.google.com/embed/reporting/13d46d17-92c0-4d2c-9ead-21c64372caea/page/gSLND',
    title: 'BQ LogCrunch Macro Indicators',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    menuTitle: siUBSGoldenSignalsMenuTitle,
    menuSubtitle: 'BQ LogCrunch Conversion',
    fromRef:
      'https://lookerstudio.google.com/u/0/reporting/13d46d17-92c0-4d2c-9ead-21c64372caea/page/gSLND',
    contact: 'platform-insights',
  },
  // from Jesu, building right now as of Feb 19, 2024. This is where all product related metrics will reside
  {
    route: '/product-dashboard',
    src: 'https://lookerstudio.google.com/embed/reporting/e0e59f2a-d1c7-4ee1-97d8-bb9d803f7e07/page/p_bk1dk38fdd',
    title: 'Product Dashboard',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    menuTitle: 'Pillars: Product Dashboard',
    menuSubtitle: 'Customer Journey, Grow Agencies, Foundation',
    fromRef:
      'https://lookerstudio.google.com/u/0/reporting/e0e59f2a-d1c7-4ee1-97d8-bb9d803f7e07',
    contact: 'catalyst', // the report is owned by Marketing Analytics, but Catalyst will manage this menu
  },
  {
    route: '/platform-evolution',
    src: 'https://lookerstudio.google.com/embed/reporting/e8fd8d98-7023-4a3b-92a0-31adde2bec5f/page/hAx3B',
    title: 'Platform Evolution',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    menuTitle: pillarFoundationalinnovationGoldenSignalsMenuTitle,
    menuSubtitle: 'Platform Evolution Golden Signals',
    fromRef:
      'https://lookerstudio.google.com/u/0/reporting/e8fd8d98-7023-4a3b-92a0-31adde2bec5f/page/hAx3B',
    contact: 'platform-insights',
  },
  {
    route: '/advanced-network-adoption',
    src: 'https://wpengine.looker.com/embed/dashboards/1139',
    title: 'Advanced Network Adoption',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    menuTitle: pillarFoundationalinnovationGoldenSignalsMenuTitle,
    menuSubtitle: 'Advanced Network Adoption',
    fromRef: 'https://wpengine.looker.com/dashboards/1139',
    contact: 'platform-insights',
  },
  {
    route: '/product-atlas',
    src: 'https://wpengine.looker.com/embed/dashboards/1507',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    title: 'Specialized Products: Atlas',
    menuTitle: pillarSpecializedProductsGoldenSignalsMenuTitle,
    menuSubtitle: 'Atlas',
    fromRef: 'https://wpengine.looker.com/dashboards/1507',
    contact: 'nebula',
  },
  {
    route: '/product-ecommerce-dashboard',
    src: 'https://wpengine.looker.com/embed/dashboards/1510',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    title: 'Specialized Products: eCommerce Dashboard',
    menuTitle: pillarSpecializedProductsGoldenSignalsMenuTitle,
    menuSubtitle: 'ECommerce',
    fromRef: 'https://wpengine.looker.com/dashboards/1510',
    contact: 'nimbus',
  },
  // from  https://wpengine.atlassian.net/wiki/spaces/ProgramMgmt/pages/3840475190/Success+of+our+Customers
  {
    route: '/product-ecommerce-plan-churn-tracker',
    src: 'https://lookerstudio.google.com/embed/reporting/d918cc9c-a169-4bd6-ba6c-95179748714e/page/p_q6q9udf2ad',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    title: 'Product: eCommerce Plan Churn Tracker',
    menuTitle: pillarSpecializedProductsGoldenSignalsMenuTitle,
    menuSubtitle: 'eCommerce Plan Churn Tracker',
    fromRef:
      'https://lookerstudio.google.com/u/0/reporting/d918cc9c-a169-4bd6-ba6c-95179748714e',
    contact: 'nimbus',
  },
  // from https://wpengine.atlassian.net/wiki/spaces/ProgramMgmt/pages/3840475190/Success+of+our+Customers
  {
    route: '/churn-dashboard',
    src: 'https://lookerstudio.google.com/embed/reporting/03cbf196-d656-45ef-9fc1-5523b22950c4/page/p_x8d1mzejdd',
    title: 'Churn Dashboard',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    menuTitle: 'Churn Dashboard',
    // menuSubtitle: ,
    fromRef:
      'https://lookerstudio.google.com/reporting/03cbf196-d656-45ef-9fc1-5523b22950c4/page/p_vv3l18m18c',
    contact: 'catalyst', // the report is owned by Marketing Analytics, but Catalyst will manage this menu
  },
  {
    route: '/cancel-and-downgrade-reasons-by-product-line',
    src: 'https://wpengine.looker.com/embed/dashboards/2715',
    title: 'Churn Reasons / Cancel and Downgrade Reasons by Product Line',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    menuTitle: 'Churn Reasons',
    menuSubtitle: 'Cancel and Downgrade Reasons by Product Line',
    fromRef: 'https://wpengine.looker.com/dashboards/2715',
    contact: 'catalyst', // the report is owned by Marketing Analytics, but Catalyst will manage this menu
  },
  {
    route: '/mrr-and-n-pacing',
    src: 'https://wpengine.looker.com/embed/dashboards/2675',
    title: 'MRR & N Pacing vs. Prior Periods',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    menuTitle: 'MRR & N Trend',
    menuSubtitle: 'MRR & N Pacing vs. Prior Periods',
    fromRef: 'https://wpengine.looker.com/dashboards/2675',
    contact: 'catalyst', // the report is owned by Marketing Analytics, but Catalyst will manage this menu
  },
  {
    route: '/product-plan-drill',
    src: 'https://wpengine.looker.com/embed/dashboards/594',
    title: 'Plan Drilldown',
    menu: iFrameMenu.GOLDEN_SIGNALS,
    menuTitle: 'Product Plan Drilldown',
    // menuSubtitle:,
    fromRef: 'https://wpengine.looker.com/dashboards/594',
    contact: 'payup',
  },
];

type IFrameRouteProps = {
  key: string;
  path: string;
  src: string;
  title: string;
  fromRef?: string;
};

export function iFrameRoute(props: IFrameRouteProps): React.ReactElement {
  const { key, path, src, title, fromRef } = props;
  return (
    <Route
      path={path}
      key={key}
      element={
        <WpeIFramePage
          {...{
            iframe: {
              src: src,
            },
            title: title,
            externalUrl: fromRef || src,
          }}
        />
      }
    />
  );
}
