// Ref: https://backstage.io/docs/features/techdocs/how-to-guides#using-techdocscustomhome
// Ref: https://github.com/backstage/backstage/blob/master/plugins/techdocs/src/home/components/TechDocsCustomHome.tsx
import { PanelType } from '@backstage/plugin-techdocs';
import { Entity } from '@backstage/catalog-model';

export const techDocsTabsConfig = [
  {
    label: "Recommended",
    panels: [
      {
        title: 'Recommended documents',
        description: 'Recommended overviews for all engineers',
        panelType: 'DocsCardGrid' as PanelType,
        filterPredicate: (entity: Entity) => entity?.metadata?.tags?.includes('recommended') ?? false,
      }
    ]
  },
  {
    label: "My docs",
    panels: [
      {
        title: 'My documents',
        description: 'Documents my teams own',
        panelType: 'DocsTable' as PanelType,
        filterPredicate: 'ownedByUser',
      }
    ]
  },
  {
    label: "All",
    panels: [
      {
        title: 'My documents',
        description: 'Documents my teams own',
        panelType: 'DocsTable' as PanelType,
        filterPredicate: () => true,
      }
    ]
  },
  {
    label: "Service Delivery",
    panels: [
      {
        title: 'Service delivery',
        description: 'Accelerate the speed at which our internal developers can design, develop, and deliver services',
        panelType: 'DocsCardGrid' as PanelType,
        filterPredicate: (entity: Entity) => entity?.metadata?.tags?.includes('service-delivery-recommended') ?? false,
      }
    ]
  },
  {
    label: "Portal dev",
    panels: [
      {
        title: 'User Portal development',
        description: 'Accelerate the speed at which our internal developers can deliver market-ready user experience',
        panelType: 'DocsCardGrid' as PanelType,
        filterPredicate: (entity: Entity) => entity?.metadata?.tags?.includes('portal-dev-recommended') ?? false,
      }
    ]
  },
]