// These roles have leadership roles over a team, and so teams are owned by them, higher in the org
// chart. Though they are not members, a team is viewed as "My team" if I have one of these roles.
export const leadershipRoles = [
  'Group Engineering Leader',
  'Engineering Group Manager',
  'Engineering Manager',
  'Group Product Leader',
  'Director / Manager Product',
  'Product Manager',
  'Architect',
  'hasMember',
];

// These roles are functional, matrixed roles. They are not leadership roles higher in the org
// chart.
export const functionalRoles = [
  'Product Designer',
  'Product Design Manager',
  'UX Researcher',
  'UXR Manager',
  'Program Manager',
  'Agile Operations',
  'Agile Advocate',
  'Technical Lead',
  'Product Marketing',
  'CX Operations',
];

export const allFunctionalRoles = [...leadershipRoles, ...functionalRoles];

// P&E Groups & Teams does not presently have a role for P&E upper management. These individuals in
// leadership have a span over all teams. Though they are not members, all teams may be viewed as
// "My team" if I have one of these roles.
export const peT1: string[] = [
  'user:default/brian.gosnell',
  'user:default/ramadass.prabhakar',
  'user:default/candace.tribble',
  'user:default/mary.johnson',
  'user:default/vicky.ness',
  'user:default/ezinne.udezue',
  'user:default/heather.brunner',
  'user:default/jason.cohen',
];
