import {
  createRouteRef,
  createExternalRouteRef,
} from '@backstage/core-plugin-api';

export const viewTechDocRouteRef = createExternalRouteRef({
  id: 'view-techdoc',
  optional: true,
  params: ['namespace', 'kind', 'name'],
});

export const rootRouteRef = createRouteRef({
  id: 'wpe-catalog',
});
