import {
  RadarEntry,
  MovedState
} from '@backstage-community/plugin-tech-radar';

export const portalTechRadarEntries: RadarEntry[] =
[
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'node',
    id: 'node',
    title: 'Backend: Node',
    quadrant: 'languages-and-frameworks',
    description: `Back end JS runtime environment
      \nFollow [Node.js Security Guidance](https://wpengine.atlassian.net/wiki/spaces/SECACT/pages/3095724313/Node.js+Security+Guidance)
      to protect against [npm supply chain attacks](https://thehackernews.com/2022/03/a-threat-actor-dubbed-red-lili-has-been.html)`,
    // last_updated: '4/4/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'ruby',
    id: 'ruby',
    title: 'Backend: Ruby, Ruby on Rails',
    quadrant: 'languages-and-frameworks',
    description: `Primarily for maintaining the User Portal since it is a Ruby on Rails app.
      \nWe are moving away from the monolithic app model and adding newer functionality in React pages which call standalone services
      external to the portal. See the "API-Centric UIs / User Centric APIs" technique. In this pattern, Ruby/Rails usage is limited to
      page routing, and building limited props to launch the React component. Often this code is generated using the
      [React Page Generator](/docs/default/system/user-portal/Portal-Front-end-Quick-Start-Guide/). The use of Ruby/Rails
      within this pattern is allowed.
      \nWe sometimes need to extend the public customer API and the private API within the portal's Ruby/Rails layer, to provide new APIs
      needed by API-centric UIs and User-centric APIs. While we prefer to build most APIs in services outside portal, this is allowed.
      Please consult with the Customer Journey teams on these use cases.
      \nGuidance is to convert pages from older technologies like slim to React when making any substantive changes to the page. For more,
      see the "Unicorn Design System Components" and "React" framework guidance. So rather than adding more Ruby/Rails elements to enhance
      older pages, convert the pages to React. Minor enhancements to Ruby/Rails elements which support older pages for maintenance, etc.
      are allowed.
      \nRuby/Rails has existing models for some elements which can be extended for your use case. A good example is creation of features
      and assigning them to accounts or users. These may impact authorization or can become props passed into React pages. Other examples
      include entitlements. The use of these elements is allowed.
      \nBeyond the exceptions, we are trying not to add new Ruby/Rails code. If you find a need to do so, we request that you reach out to
      Customer Journey using the Slack channel in the footer to discuss the use case, and include them as reviewers on the implementation.
      \nIn fact, we encourage teams to partner with Customer Journey in reducing the Ruby/Rails footprint in the portal. For example, as you
      convert older pages to the API-Centric UI pattern and use User Centric APIs to get required data on demand, the models that support
      the older pages, and the portal syncs for such models become unnecessary. See the "Syncing Data to the Portal" technique.`,
    // last_updated: '3/9/2022',
    // last_reviewed: '3/9/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'shell-scripting',
    id: 'shell-scripting',
    title: 'Backend: Shell scripting',
    quadrant: 'languages-and-frameworks',
    description: `We are moving towards automating routine tasks via server side scripts. For e.g. the CI/CD tasks. For example, the
      migration to Private Spaces relied on shell scripting to capture the work in reviewable scripts.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'coffee-script',
    id: 'coffee-script',
    title: 'Frontend: CoffeeScript',
    quadrant: 'languages-and-frameworks',
    description: `Use React and Unicorn for new experiences. Convert pages to React when making any substantive changes to the page. For
      more, see the Unicorn Design System Components framework and React language.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'erb',
    id: 'erb',
    title: 'Frontend: erb',
    quadrant: 'languages-and-frameworks',
    description: `Use React and Unicorn for new experiences. Convert pages to React when making any substantive changes to the page.
      For more, see the Unicorn Design System Components framework and React language.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'javascript',
    id: 'javascript',
    title: 'Frontend: Javascript',
    quadrant: 'languages-and-frameworks',
    description: `Avoid pure Javascript. TypeScript is required.
      [Why create TypeScript](https://www.typescriptlang.org/why-create-typescript)`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'jbuilder',
    id: 'jbuilder',
    title: 'APIs: jbuilder',
    quadrant: 'languages-and-frameworks',
    description: `Use to form structured JSON within Rails controllers for JSON return objects, such as objects returned by an API.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'jquery',
    id: 'jquery',
    title: 'Frontend: jQuery',
    quadrant: 'languages-and-frameworks',
    description: `Use React and Unicorn for new experiences. Convert pages to React when making any substantive changes to the page.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'react',
    id: 'react',
    title: 'Frontend: React',
    quadrant: 'languages-and-frameworks',
    description: `Use React and Unicorn for new experiences. Convert pages to React when making any substantive changes to the page
      For more, see the Unicorn Design System Components framework and React language.
      \nSee the
      [WP Engine React + Typescript Code Style Guide](/docs/default/system/user-portal/WP-Engine-React-%2B-Typescript-Code-Style-Guide/)
      for valuavle content about building React.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'slim',
    id: 'slim',
    title: 'Frontend: slim',
    quadrant: 'languages-and-frameworks',
    description: `Use React and Unicorn for new experiences. Convert pages to React when making any substantive changes to the page.
    For more, see the Unicorn Design System Components framework and React language.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'typescript',
    id: 'typescript',
    title: 'Frontend: TypeScript',
    quadrant: 'languages-and-frameworks',
    description: `TypeScript is required for all browser-hosted UI code. Do not use Javascript.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: 'https://wpengine.atlassian.net/wiki/spaces/PROD/pages/2626683881/Unicorn+Design+System',
    key: 'unicorn-design-system-components',
    id: 'unicorn-design-system-components',
    title: 'Frontend: Unicorn Design System',
    quadrant: 'languages-and-frameworks',
    description: `These components are the manifestation of the
      [Unicorn Design System](https://wpengine.atlassian.net/wiki/spaces/PROD/pages/2626683881/Unicorn+Design+System)
      in React code within the User Portal. The use of UDS and its component library within React are required for all new pages, or when
      substantial modifications are made to any existing page. The best way to view the available components is within
      [Storybook](/docs/default/system/user-portal/Storybook-quickstart-guide/).
      For more on Storybook see the Tools quadrant.
      \nTeams are encouraged to extend Unicorn through the
      [Unicorn Design System (UDS) Intake Process](https://wpengine.atlassian.net/wiki/spaces/SSPG/pages/2880045100/Unicorn+Design+System+UDS+Intake+Process)
      should the component library be missing a component required for your design.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'rails-routing',
    id: 'rails-routing',
    title: 'Routing: Rails Routing',
    quadrant: 'languages-and-frameworks',
    description: `Rails routing is presently required to launch React UIs`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'react-routing',
    id: 'gemfreact-routingury',
    title: 'Routing: React Routing',
    quadrant: 'languages-and-frameworks',
    description: `Use within React components and experiences which are fully in React. Use Rails routing across experiences. `,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'bootstrap',
    id: 'bootstrap',
    title: 'Styling: Bootstrap',
    quadrant: 'Techniques',
    description: `Bootstrap remains in the Portal to support styling on legacy pages that predate our preferred front end development
      methodologies. We are in the process of removing dependencies upon Bootstrap.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0006-styling.md',
    key: 'css',
    id: 'css',
    title: 'Styling: CSS, SCSS',
    quadrant: 'Techniques',
    description: `CSS and SCSS rules often accumulate and are difficult to delete, as dependents are hard to determine. We recommend that
      you do not add additional CSS/SCSS to the user portal, and instead follow our styling guidance in the styling ADR:
      [ADR 006: Styling in the User Portal](/catalog/default/system/user-portal/decisions?record=0006-styling.md)`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0006-styling.md',
    key: 'mui-makestyles',
    id: 'mui-makestyles',
    title: 'Styling: Material UI makeStyles',
    quadrant: 'Techniques',
    description: `We are deprecating this pattern and it will soon not be possible.
      \nFor more, see [ADR 006: Styling in the User Portal](/catalog/default/system/user-portal/decisions?record=0006-styling.md).`,
    // last_updated: '8/5/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0006-styling.md',
    key: 'mui-themes',
    id: 'mui-themes',
    title: 'Styling: Material UI Themes',
    quadrant: 'Techniques',
    description: `We are deprecating this pattern and it will soon not be possible.
      \nFor more, see [ADR 006: Styling in the User Portal](/catalog/default/system/user-portal/decisions?record=0006-styling.md).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0006-styling.md',
    key: 'sx-prop',
    id: 'sx-prop',
    title: 'Styling: sx prop',
    quadrant: 'Techniques',
    description: `For one-off customization developers should use the sx prop. For example, putting margin or flex styling onto a unicorn
      component. Note that the sx prop can only be used on MUI based components.
      \nFor more, see [ADR 006: Styling in the User Portal](/catalog/default/system/user-portal/decisions?record=0006-styling.md).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0006-styling.md',
    key: 'mui-styled',
    id: 'mui-styled',
    title: 'Styling: Material UI styled() utility',
    quadrant: 'Techniques',
    description: `For reusable customization developers should use the styled() utility from @mui/material. For example, putting the same
      padding on a list item component that will be used several times. The styled() utility can be used on MUI components as well as HTML
      elements.
      \nFor more, see [ADR 006: Styling in the User Portal](/catalog/default/system/user-portal/decisions?record=0006-styling.md).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: 'https://wpengineapi.com/',
    key: 'customer-api',
    id: 'customer-api',
    title: 'APIs: Customer API (CAPI)',
    quadrant: 'Platforms',
    // topic: 'APIs',
    description: `Use of the customer API within API-Centric UIs / User Centric APIs is encouraged. Extending the Customer API
      within the Rails layer in the user portal is on hold. Consult with Catalyst before
      extending the customer API.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/api/portal-user-api',
    key: 'private-user-centric-api',
    id: 'private-user-centric-api',
    title: 'APIs: User Portal Private API',
    quadrant: 'Platforms',
    description: `Use of the User Portal private API within API-Centric UIs / User Centric APIs is encouraged. Generally, new APIs would be built
      in services rather than the portal Rails layer. Consult with Customer Journey before extending the CAPI private API.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'browser-local-storage',
    id: 'browser-local-storage',
    title: 'Data: Browser local storage',
    quadrant: 'Platforms',
    description: `Where the portal needs data to make UIs perform well, browser local storage in API-Centric UIs is preferred.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'portal-db',
    id: 'portal-db',
    title: 'Data: Extending portal DB',
    quadrant: 'Platforms',
    description: `We prefer the API-Centric UIs / User Centric APIs, so data is maintained in services that are the data authority rather
      than pushing secondary copies of data to the portal DB. We should not be syncing further models / entities to the portal DB.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'redis',
    id: 'redis',
    title: 'Data: Redis',
    quadrant: 'Platforms',
    description: `Redis is used as a server-side cache. We have 2 Redis databases used in the Portal. One for Rails caching and another
      for longer term, session and background workers via Resque. Where the portal needs data to make UIs perform well, caching is
        preferred over adding portal database models. The use of browser local storage in API-Centric UIs is preferred.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'aws-s3',
    id: 'aws-s3',
    title: 'Data: S3',
    quadrant: 'Platforms',
    description: `Prefer that S3 usage is wrapped by services using the "API-Centric UIs / User Centric APIs" pattern.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'hubspot',
    id: 'hubspot',
    title: 'Email: Hubspot',
    quadrant: 'Platforms',
    description: `Use Hubspot for portal emails`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'sendgrid',
    id: 'sendgrid',
    title: 'Email: Sendgrid',
    quadrant: 'Platforms',
    description: `Use Hubspot for portal emails`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0015-origin-server-protection-on-the-user-portal.md',
    key: 'heroku-private-spaces',
    id: 'heroku-private-spaces',
    title: 'Hosting: Heroku Private Spaces',
    quadrant: 'Platforms',
    description: `We need origin server protection on the User Portal on Heroku - to prevent hacking attempts and DDoS attacks.
      Cloudflare protects the User Portal web but cannot prevent origin server attacks or access to the Postgres database which is
      accessbile on the internet. See
      [Origin Server Protection on the User Portal](/catalog/default/system/user-portal/decisions?record=0015-origin-server-protection-on-the-user-portal.md)
      and
      [Migration to Heroku Private Spaces](https://wpengine.atlassian.net/wiki/spaces/SE/pages/2917236882/Migration+to+Heroku+Private+Spaces)
      for details.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/System/user-portal/Docker-Setup/',
    key: 'local-docker',
    id: 'local-docker',
    title: 'Local Dev: Docker',
    quadrant: 'Platforms',
    description: `While some use this, it is not actively maintained. See
      [the wiki for local development options](/docs/default/System/user-portal/Docker-Setup/).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/System/user-portal/Hybrid-Development-Environment-%28Local--%26-Docker%29/',
    key: 'local-hybrid',
    id: 'local-hybrid',
    title: 'Local Dev: Hybrid',
    quadrant: 'Platforms',
    description: `While some use this, it is not actively maintained. See
      [the wiki for local development options](/docs/default/System/user-portal/Hybrid-Development-Environment-%28Local--%26-Docker%29/).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/System/user-portal/macOS-local/',
    key: 'local-install',
    id: 'local-install',
    title: 'Local Dev: MacOS local',
    quadrant: 'Platforms',
    description: `Recommended. See [the wiki for local development options](/docs/default/System/user-portal/macOS-local/).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/Component/user-centric-apis',
    key: 'api-from-backend',
    id: 'api-from-backend',
    title: 'APIs: Calls from Rails back-end',
    quadrant: 'Techniques',
    description: `We prefer APIs called from the browser, made in an user context over having the back end call APIs with a root-level
      credential. See "API-Centric UIs / User Centric APIs".`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'database-authentication',
    id: 'database-authentication',
    title: 'Authentication: Database',
    quadrant: 'Techniques',
    description: `Use of OAuth2 is recommended, even for local or development environments. API-centric UIs will not work with database
      authentication.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'oauth2',
    id: 'oauth2',
    title: 'Authentication: OAuth2',
    quadrant: 'Techniques',
    description: `All user authentication should be against our CIAM Okta OAuth2 provider in the same environment (Okta dev for Heroku
        dev & local; staging; production)`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0012-deprecating-components.md',
    key: 'deprecated-components',
    id: 'deprecated-components',
    title: 'Mark deprecated components',
    quadrant: 'Techniques',
    description: `Deprecated components should be annotated with a JSDoc @deprecated tag. Deprecated components should have their import
      restricted with ESLint by setting it as a restricted import with no-restricted-imports. See
      [ADR 012 - Deprecate components using JSDoc and ESLint](/catalog/default/system/user-portal/decisions?record=0012-deprecating-components.md)`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0011-use-symbols-for-http-status-codes.md',
    key: 'symbols-for-http-status',
    id: 'symbols-for-http-status',
    title: 'Symbols for HTTP Status Codes',
    quadrant: 'Techniques',
    description: `Avoid the use of numeric HTTP response values in code to make code readable. See
      [ADR 011 - Use Symbols / Constants for HTTP Status Codes](/catalog/default/system/user-portal/decisions?record=0011-use-symbols-for-http-status-codes.md)`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: 'https://docs.google.com/presentation/d/1ofeCyYrFx7C8k5LhDDbx902Rg4NCffYxBCilLbjFt1A/edit',
    key: 'syncs',
    id: 'syncs',
    title: 'Data: Syncing Data to the Portal',
    quadrant: 'Techniques',
    description: `We prefer the API-Centric UIs / User Centric APIs, so data is maintained in services that are the data authority rather
      than pushing secondary copies of data to the portal DB. We should not be syncing further models / entities to the portal DB.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/Component/user-centric-apis',
    key: 'user-centric-apis-outside-portal',
    id: 'user-centric-apis-outside-portal',
    title: 'API-Centric UIs / User Centric APIs',
    quadrant: 'Techniques',
    description: `Recommended for new User Portal UIs and the APIs that support them. See documentation at
      [Pattern: API-Centric UIs calling User-Centric APIs](/docs/default/Component/user-centric-apis).
      \nThe user-centric API pattern provides a method for building APIs that are called in the context of a user, typically a customer,
      who is logged into the portal or calling the Customer API. It does include impersonating a customer in the UI and calling APIs.
      \nThe user-centric API pattern is not presently intended for governing employee administrative access to APIs, separate from acting
      as a customer and the user portal. Use Google authentication or corporate Okta for company facing applications that require user
      authentication. We are evaluating the use of Okta tokens from our CIAM Okta environments (which link to corporate through SSO) for
      this purpose within PlatformOne, but the use case is presently experimental.
      \nThe user-centric API pattern is not intended for service-to-service communication.Use Google IAM or MTLS for service-to-service
      authentication. MTLS is recommended as the primary mechanism when calling Service to service within SDP. Google IAM is used for
      other cases, using Google service accounts to represent the service.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'business-logic',
    id: 'business-logic',
    title: 'Business Logic',
    quadrant: 'Techniques',
    description: `Business logic should be shifted to services called through the "API-Centric UIs / User Centric APIs" pattern.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/System/user-portal/How-to-check-if-an-account-has-an-entitlement/',
    key: 'check-entitlements',
    id: 'check-entitlements',
    title: 'Checking entitlements',
    quadrant: 'Techniques',
    description: `This is the recommended way to enable or disable capabilities based on what the user purchased. Presently, there is not
      an API for this for use with the API-centric pattern, so we're passing entitlements needed by React pages through props. See
      [How to check an account’s entitlements and features?](/docs/default/System/user-portal/How-to-check-if-an-account-has-an-entitlement/)`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'check-plan',
    id: 'check-plan',
    title: 'Checking the Plan',
    quadrant: 'Techniques',
    description: `We are moving away from plan checks in favor of checking entitlements`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0009-use-circuit-breaker-pattern-for-fault-tolerance-and-resilience.md',
    key: 'circuit-breakers',
    id: 'circuit-breakers',
    title: 'Circuit breakers',
    quadrant: 'Techniques',
    description: `Circuit breakers are recommended for API calls that are critical to the user experience, like APIs that control menus or
      check entitlements. See
      [ADR 009 - Use circuit breaker pattern for fault tolerance and resilience](/catalog/default/system/user-portal/decisions?record=0009-use-circuit-breaker-pattern-for-fault-tolerance-and-resilience.md)`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'deployment-configuration',
    id: 'deployment-configuration',
    title: 'Deployment and configuration logic',
    quadrant: 'Techniques',
    description: `Deployment and configuration logic should not be in the UI. It should be wrapped by services using the
    "API-Centric UIs / User Centric APIs" pattern. This includes further cases using WP-CLI. Consider soapbox services for these.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'presentation-logic',
    id: 'presentation-logic',
    title: 'Presentation Logic',
    quadrant: 'Techniques',
    description: `Presentation logic should be in React UIs.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0010-use-query-objects.md',
    key: 'query-objects',
    id: 'query-objects',
    title: 'Query objects',
    quadrant: 'Techniques',
    description: `Use Query Objects for extracting complex queries on ActiveRecord relations. See
      [ADR 010 - Use Query Objects](/catalog/default/system/user-portal/decisions?record=0010-use-query-objects.md)`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'ab-testing',
    id: 'ab-testing',
    title: 'Rollouts: A/B testing',
    quadrant: 'Techniques',
    description: `AB Testing, such as within the portal Signup UI, uses the
      [Rails split gem](https://github.com/splitrb/split)
      \nFor more information, see
      [Tools and Methodologies for Managing Product Availability](/docs/default/Component/managing-product-availability)`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/System/user-portal/Feature-Flags/',
    key: 'feature-flags',
    id: 'feature-flags',
    title: 'Rollouts: Features',
    quadrant: 'Techniques',
    description: `The feature and account_feature model (database support) are
      [documented ihere](/docs/default/System/user-portal/Feature-Flags/).
      \nThey provide allow list features on accounts or users. Because we have been rolling out more features to a limited set of customers,
      we built this solution for tracking feature flags enabled for particular accounts. We recently added support for rollouts to
      individual users.\n<p>Features can become unwieldy in large rollouts spanning all accounts.\n<p>Features are typically used in
      conjunction with an environment variable to allow for a rollout to a portion of accounts and then for GA, an environment variable
      can be flipped.
      \nCurrently, there is not a UI to allow internal users to toggle feature flags. Portal scrum members toggle these via the Rails
      console.
      \nFor more information, see
      [Tools and Methodologies for Managing Product Availability](/docs/default/Component/managing-product-availability)`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0014-guidance-for-managing-settings.md',
    key: 'environment-variables',
    id: 'environment-variables',
    title: 'Environment, config variables',
    quadrant: 'Techniques',
    description: `Use settings without environment variables for typical use. Use environment variables temporarily during rollouts,
      accessed through Rails settings, then delete them. Use environment variables for credentials, since we would not reveal credentials
      in the repo. See
      [ADR 014 - Guidance for Managing Settings](/catalog/default/system/user-portal/decisions?record=0014-guidance-for-managing-settings.md)
      for detailed guidance.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0014-guidance-for-managing-settings.md',
    key: 'google-secrets-manager',
    id: 'google-secrets-manager',
    title: 'Google Secrets Manager',
    quadrant: 'Techniques',
    description: `Configure secrets and other credentials in Google Secrets Manager, which the build places into Heroku Configuration
      Variables, and access their values through Rails settings. See
      [ADR 014 - Guidance for Managing Settings](/catalog/default/system/user-portal/decisions?record=0014-guidance-for-managing-settings.md)
      for detailed guidance.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0014-guidance-for-managing-settings.md',
    key: 'settings-file',
    id: 'settings-file',
    title: 'Settings File',
    quadrant: 'Techniques',
    description: `Use settings without environment variables for typical use. Do not place credentials in a settings file, though the
      value may be retrieved through a setting. See
      [ADR 014 - Guidance for Managing Settings](/catalog/default/system/user-portal/decisions?record=0014-guidance-for-managing-settings.md)
      for detailed guidance.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'snapshot-testing',
    id: 'snapshot-testing',
    title: 'Snapshot tests',
    quadrant: 'Techniques',
    description: `Snapshot tests are dependent upon implementation details that may or may not yield any visual difference in component
      visual styles. If testing functionality, you should write tests with Reacting Testing Library. If the goal is to prevent visual style
      regressions, you should document your component in Storybook and utilize Chromatic's snapshot diffing to catch regressions.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'bundler',
    id: 'bundler',
    title: 'Distribution: Bundler',
    quadrant: 'Tools',
    description: `For managing ruby gems bundling - all the gemfile based dependency management is done with this. Use it for updating the
      local dev environment and it is used in the build pipeline as well.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'npm',
    id: 'npm',
    title: 'Distribution: npm',
    quadrant: 'Tools',
    description: `We use Node Package Manager (npm) for package management. Follow
      [Node.js Security Guidance](https://wpengine.atlassian.net/wiki/spaces/SECACT/pages/3095724313/Node.js+Security+Guidance)
      to protect against [npm supply chain attacks](https://thehackernews.com/2022/03/a-threat-actor-dubbed-red-lili-has-been.html)`,
    // last_updated: '4/4/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'resque',
    id: 'resque',
    title: 'Background Workers: Resque',
    quadrant: 'Tools',
    description: `As our background job processor (it is based on Redis), it is accessible via the ResqueWeb plugin to admins users on the
      Portal. Great for monitoring the current state of background worker queues. New background jobs are added to newly created or
      existing Resque queues.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'google-cloud-build',
    id: 'google-cloud-build',
    title: 'CI/CD: Google Cloud Build',
    quadrant: 'Tools',
    description: `Any changes to the build process should be made here.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'github',
    id: 'github',
    title: 'CI/CD: GitHub',
    quadrant: 'Tools',
    description: `Besides being the corporate Git Repo services provider, GitHub is how we manage PR processes as well as technical
      documentation via README files and Wikis`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'jenkins',
    id: 'jenkins',
    title: 'CI/CD: Jenkins',
    quadrant: 'Tools',
    description: `Portal is actively moving away from Jenkins.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/system/user-portal/Freeze-PR-merges/',
    key: 'merge-freeze',
    id: 'merge-freeze',
    title: 'CI/CD: MergeFreeze',
    quadrant: 'Tools',
    description: `MergeFreeze can be used to block all PRs from being merged. This is useful when some deploy requires a Heroku rollback,
      and the team needs time to deploy the fix.
      \nMergeFreeze adds a required check to each PR. During normal operations, this check will always be green and the PR can be merged
      like usual. When PRs are frozen, the check will be failing and no PRs will be able to merge unless manually overwritten through
      the MergeFreeze UI.
      \nSee [the wiki](/docs/default/system/user-portal/Freeze-PR-merges/).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'terraform',
    id: 'terraform',
    title: 'CI/CD: Terraform',
    quadrant: 'Tools',
    description: `We manage Heroku permissions and access via Terraform-Heroku-Permissions. The Portal applications within Heroku and some
      of their add-ons are also provisioned Terraform via the my-wpe repo. Also for managing Redis memory monitor for Portal in DataDog.
      We want to reduce the amount of configuration and tweaking that is happening manually via the Heroku GUI and move as much as we can
      to Terraform.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Evaluate',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'simplecov',
    id: 'simplecov',
    title: 'Simplecov',
    quadrant: 'Tools',
    description: `Prefer code coverage via CodeClimate`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'code-climate',
    id: 'code-climate',
    title: 'CodeClimate',
    quadrant: 'Tools',
    description: `We are slowly evolving our approach to respond to the suggestions given by CC on coding standards along with static code
      analysis and code coverage. The refactoring suggestions to decrease code complexity are worth implementing if time permits.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'eslint',
    id: 'eslint',
    title: 'ESLint',
    quadrant: 'Tools',
    description: `Javascript and Typescript Linter - we have git hooks that run the linters on Git Push and also in the build pipeline.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/System/user-portal/Front-End-FAQs/#27-how-should-i-check-that-my-page-is-accessible-to-users',
    key: 'accessibility-testing',
    id: 'accessibility-testing',
    title: 'Accessibility Testing',
    quadrant: 'Techniques',
    description: `One tool to help automate some accessibility testing is jest-axe. Accessibility linting is also still in place with
      jxs-a11y, and these two tools are complementary. jest-axe runs accessibility checks against a rendered DOM tree, and will catch
      things like improper heading order, or ineffective aria labeling. jsx-a11y will catch things in a linting stage in the editor, such
      as missing alternative text in images or missing label text.\n\nIt's important to note that these are just two tools to help catch
      accessibility errors, and they will never be able to check everything. Screen readers and manual key interactions are still necessary
      to ensure we are building accessible products. Read more here:
      \n* [Front End FAQs](/docs/default/System/user-portal/Front-End-FAQs/#27-how-should-i-check-that-my-page-is-accessible-to-users)
      \n* [JavaScript Testing - Testing Accessibility](/docs/default/System/user-portal/JavaScript-Testing/#testing-accessibility)`,
    // last_updated: '8/5/2022',
    // last_reviewed: '',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'rubocop',
    id: 'rubocop',
    title: 'Rubocop',
    quadrant: 'Tools',
    description: `Ruby coding standards enforcer - we have git hooks that run rubocop on Git Push and also in the build pipeline.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/System/user-portal/How-to-use-React-Query/',
    key: 'react-query',
    id: 'react-query',
    title: 'Frontend: React Query',
    quadrant: 'Tools',
    description: `Recommended for all new API-centric pages in React. See
      [ADR 007 - React Query](/catalog/default/system/user-portal/decisions?record=0007-react-query.md)
      and [React Query](/docs/default/System/user-portal/How-to-use-React-Query/)`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'postgresql',
    id: 'postgresql',
    title: 'Data: Postgres',
    quadrant: 'Tools',
    description: `Since it is the database that powers the User Portal, it is critical we understand how to tune it for better performance.
      Logs on the events on the database in DataDog help along with the tools on Heroku. `,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'page-generator',
    id: 'page-generator',
    title: 'Frontend: Page Generator',
    quadrant: 'Tools',
    description: `We recommend that you follow the process documented in the
      [Portal Front-end Quick Start Guide](/docs/default/system/user-portal/Portal-Front-end-Quick-Start-Guide/)
      to generate the portal back-end code and the initial page skeleton. The generator and the process outlined in the document eliminates
      many manual process steps.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'storybook-chromatic',
    id: 'storybook-chromatic',
    title: 'Frontend: Storybook & Chromatic',
    quadrant: 'Tools',
    description: `[Storybook](https://storybook.js.org/) is an open source tool for developing UI components in isolation.
      Storybook lets you create a "story" file where you can then import your component and create various use case examples in an
      iFramed sandbox using that component. This provides an organized and focused environment to build new components and work on existing
      ones.
      \nChromatic automates gathering UI feedback, visual testing, and documentation, so developers can iterate faster with less manual work.
      \nYou can access the most recent Storybook in Chromatic [here](https://main--60526704664c4e002161d5e9.chromatic.com/).
      \nTo work with Storybook in your UI development, begin with the
      [Storybook Quick Start](/docs/default/system/user-portal/Storybook-quickstart-guide/).
      Once you have more experience with UI development, you may want to move on to the
      [Storybook Guide](/docs/default/system/user-portal/Storybook-guide/).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'webpack',
    id: 'webpack',
    title: 'Frontend: Webpack',
    quadrant: 'Tools',
    description: `JS module bundler`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'hirefire',
    id: 'hirefire',
    title: 'Hosting: HireFire',
    quadrant: 'Tools',
    description: `We use this Heroku addon for scaling worker dynos automatically. Scaling can be done per load as well as within windows
      of time during the day.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'datadog',
    id: 'datadog',
    title: 'Observe: Datadog',
    quadrant: 'Tools',
    description: `Datadog is a critical tool for analyzing the logs and running the Portal Health Monitor. Setting up similar dashboard
      for monitoring and alerting is highly encouraged.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/system/user-portal/Rollbar-logging-managment/',
    key: 'rollbar',
    id: 'rollbar',
    title: 'Observe: Rollbar',
    quadrant: 'Tools',
    description: `Use only for tracking of unexpected exceptions that require human attention. Exceptions handled in code, or which are
      expected, should be logged only. See [the wiki](/docs/default/system/user-portal/Rollbar-logging-managment/).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'brakeman',
    id: 'brakeman',
    title: 'Security: Brakeman',
    quadrant: 'Tools',
    description: `Vulnerability scanner specifically designed for Ruby on Rails applications - it is run as part of the build pipeline.
      Besides failing the build, the warnings are also useful for improving security.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0016-adopt-playwright-as-e2e-testing-tool.md',
    key: 'cypress',
    id: 'cypress',
    title: 'Cypress',
    quadrant: 'Tools',
    description: `Migrate Cypress tests to Playwright. See
      [ADR 016 - Adopt Playwright as E2E testing tool](/catalog/default/system/user-portal/decisions?record=0016-adopt-playwright-as-e2e-testing-tool.md).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'enzyme',
    id: 'enzyme',
    title: 'Enzyme',
    quadrant: 'Tools',
    description: `Enzyme usage is deprecated and should not be used for new tests. Test frontend code with React Testing Library.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/System/user-portal/JavaScript-Testing/#react-testing',
    key: 'jest',
    id: 'jest',
    title: 'Jest',
    quadrant: 'Tools',
    description: `Use Jest alongside the
      [React Testing Library](https://testing-library.com/). See [JavaScript Testing](/docs/default/System/user-portal/JavaScript-Testing/#react-testing).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/Component/user-centric-apis/Guide_How_to_build_API-Centric_UI_s/#start-with-mocks',
    key: 'msw',
    id: 'msw',
    title: 'Mock Service Worker',
    quadrant: 'Tools',
    description: `Portal developers are moving towards Mock Service Worker (MSW) for creating mocks. Initially, start by using MSW within
    stories. Later, as you begin writing React tests, MSW makes it easy to use mocks in those tests. There are some examples linked
    [in the Start with Mocks section of Pattern: API-Centric UIs calling User-Centric APIs](/docs/default/Component/user-centric-apis/Guide_How_to_build_API-Centric_UI_s/#start-with-mocks)`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'mock-axios',
    id: 'mock-axios',
    title: 'mockAxios',
    quadrant: 'Tools',
    description: `While we recommend Mock Service Worker (MSW), use of mockAxios within components already using this style is OK.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0016-adopt-playwright-as-e2e-testing-tool.md',
    key: 'playwright',
    id: 'playwright',
    title: 'Playwright',
    quadrant: 'Tools',
    description: `Adopt Playwright as E2E testing tool. See
    [ADR 016 - Adopt Playwright as E2E testing tool](/catalog/default/system/user-portal/decisions?record=0016-adopt-playwright-as-e2e-testing-tool.md).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/docs/default/System/user-portal/JavaScript-Testing/#react-testing',
    key: 'react-testing-library',
    id: 'react-testing-library',
    title: 'React Testing Library',
    quadrant: 'Tools',
    description: `The [React Testing Library](https://testing-library.com/), react-testing-library, is a great utility to work alongside
      Jest. It makes you focus more on writing tests the way an actual user would interact with your application. See
      [the wiki](/docs/default/System/user-portal/JavaScript-Testing/#react-testing).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'rspec',
    id: 'rspec',
    title: 'RSpec',
    quadrant: 'Tools',
    description: `[RSpec](https://rspec.info/) is our standard for unit testing of Rails code in the portal server.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-03-01'),
      },
    ],
    url: '/catalog/default/system/user-portal/decisions?record=0013-use-wistia-as-video-standard.md',
    key: 'wistia',
    id: 'wistia',
    title: 'Frontend: Wistia',
    quadrant: 'Tools',
    description: `Use Wistia because it aligns with marketing and makes it easier for our designers to supply video designs and embed codes
      to Portal developers that we have a process for supporting. The presentation of the videos will be the same in style and behaviour
      as our main website. We can leverage a Wistia account already used in the company. See
      [ADR 013 - Use Wistia as Video Standard](/catalog/default/system/user-portal/decisions?record=0013-use-wistia-as-video-standard.md).`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-03-01'),
      },
    ],
    url: '#',
    key: 'puma',
    id: 'puma',
    title: 'Backend: Puma',
    quadrant: 'Tools',
    description: `We need to keep Puma version current with the latest security updates since it our web server.`,
    // last_updated: '3/1/2022',
    // last_reviewed: '3/1/2022',
  }
];
