// Ref: https://github.com/RoadieHQ/roadie-backstage-plugins/blob/main/plugins/frontend/backstage-plugin-iframe/src/components/utils/helpers.ts
export const determineIFrameError = (
  allowList: string[] | undefined,
  url: string | undefined,
  title: string | undefined,
) => {
  if (!url || url === '') {
    return 'No url field provided. Please pass it to populate the iframe.';
  } else if (!url.startsWith('https://')) {
    return `The url '${url}' for Iframe must be a https protocol but is not.`;
  } else if (!allowList) {
    return 'No iframe allow list defined in the config.';
  } else if (url && allowList && !allowList.includes(new URL(url).hostname)) {
    return `The url ${url} for Iframe is not included in the allowlist in the Backstage config file.`;
  } else if (!title || title === '') {
    return 'No title provided. Please include the title property in your iFrame.';
  }
  return '';
};
