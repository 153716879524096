import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import { WpeTechRadarApi } from './lib/wpeTechRadars/wpeTechRadarClient';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import {
  apiDocsConfigRef,
  defaultDefinitionWidgets,
} from '@backstage/plugin-api-docs';
import { ApiEntity } from '@backstage/catalog-model';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { wpeGrpcDocsApiWidget } from './components/api-docs/wpeGrpcDocsApiWidget';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory(techRadarApiRef, new WpeTechRadarApi()),
  createApiFactory({
    api: apiDocsConfigRef,
    deps: {},
    // @ts-ignore
    factory: () => {
      // load the default widgets
      const definitionWidgets = defaultDefinitionWidgets();

      // add the grpc-docs api widget to the definition widgets
      definitionWidgets.push(wpeGrpcDocsApiWidget);

      return {
        getApiDefinitionWidget: (apiEntity: ApiEntity) => {
          // custom rendering for grpc
          if (apiEntity.spec.definition === 'techdocs') {
            return {
              type: apiEntity.spec.type,
              title: 'Docs',
              component: EntityTechdocsContent,
            };
          }

          // fallback to the defaults
          return definitionWidgets.find(d => d.type === apiEntity.spec.type);
        },
      };
    },
  }),
];
