/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ArchitectureIcon from '@material-ui/icons/Build';
import AdrIcon from '@material-ui/icons/Exposure';
import ArchitecturePrinciplesIcon from '@material-ui/icons/PlaylistAddCheck';
import StandardsIcon from '@material-ui/icons/Class';
import MacroArchitectureIcon from '@material-ui/icons/PhotoLibraryOutlined';
import PatternsIcon from '@material-ui/icons/Toll';
import CategoryIcon from '@material-ui/icons/Category';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import GoldenSignalsIcon from '@material-ui/icons/TrendingUp';
import { useApp } from '@backstage/core-plugin-api';
import { WpeMyGroupsSidebarItem } from 'backstage-plugin-wpe-catalog';
import GroupIcon from '@material-ui/icons/People';
import { leadershipRoles, peT1 } from '../../lib/WpeOrg';
import { iFrameMenu, iFrameRoutes, routeGCPSpendByProjectsWPEngine } from '../../iFrameRoutes';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

function sidebarSubmenuIFrames(menu: iFrameMenu) {
  const icon = menu === iFrameMenu.COST ? MoneyIcon : GoldenSignalsIcon;
  return iFrameRoutes.map((iframe, index) => {
    if (iframe.menu === menu) {
      return (
        <SidebarSubmenuItem
          key={index}
          icon={icon}
          to={iframe.route}
          title={iframe.menuTitle || ''}
          subtitle={iframe.menuSubtitle}
        />
      );
    }
    return null;
  });
}

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar submenuOptions={{ drawerWidthOpen: 360 }}>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <WpeMyGroupsSidebarItem
          singularTitle="My team"
          pluralTitle="My teams"
          icon={GroupIcon}
          filter={{ 'spec.type': 'team' }}
          roles={leadershipRoles}
          canViewAllGroups={peT1}
        />
        <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog">
          <SidebarSubmenu title="Catalog">
            <SidebarSubmenuItem
              title="Domains"
              to="catalog?filters[kind]=domain"
              icon={useApp().getSystemIcon('kind:domain')}
            />
            <SidebarSubmenuItem
              title="Systems"
              to="catalog?filters[kind]=system"
              icon={useApp().getSystemIcon('kind:system')}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Components"
              to="catalog?filters[kind]=component"
              icon={useApp().getSystemIcon('kind:component')}
            />
            <SidebarSubmenuItem
              title="Services"
              to="catalog?filters[type]=service"
              icon={useApp().getSystemIcon('kind:component')}
            />
            <SidebarSubmenuItem
              title="Patterns"
              to="catalog?filters[type]=pattern"
              icon={PatternsIcon}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Cross-domain APIs"
              to="catalog?filters[kind]=api&filters[tags]=cross-domain-api"
              icon={useApp().getSystemIcon('kind:api')}
            />
            <SidebarSubmenuItem
              title="All APIs"
              to="catalog?filters[kind]=api&filters[user]=all"
              icon={useApp().getSystemIcon('kind:api')}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Groups & teams"
              to="catalog?filters[kind]=group"
              icon={useApp().getSystemIcon('kind:group')}
            />
            <SidebarSubmenuItem
              title="Users"
              to="catalog?filters[kind]=user"
              icon={useApp().getSystemIcon('kind:user')}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Resources"
              to="catalog?filters[kind]=resource"
              icon={useApp().getSystemIcon('kind:resource')}
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs">
          <SidebarSubmenu title="APIs">
            <SidebarSubmenuItem
              icon={ExtensionIcon}
              to="api-docs"
              title="API Explorer"
              subtitle="Discover API definitions"
            />
            <SidebarSubmenuItem
              icon={StandardsIcon}
              to="/docs/default/Domain/apis"
              title="API standards"
              subtitle="Guidance to build APIs"
            />
            <SidebarSubmenuItem
              icon={PatternsIcon}
              to="/docs/default/Component/user-centric-apis/Guide_Developing_User-Centric_APIs/"
              title="User-centric APIs"
              subtitle="Build APIs supporting UIs"
            />
            <SidebarSubmenuItem
              icon={LibraryBooks}
              to="/docs/default/resource/backstage-catalog/documenting-apis/"
              title="Documenting APIs"
              subtitle="Publish APIs to Backstage"
            />
            <SidebarSubmenuItem
              icon={LibraryBooks}
              to="/docs/default/resource/backstage-catalog/pubsub/"
              title="Docs for Pub/Sub"
              subtitle="Pub/Sub in Backstage"
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarItem
          icon={MoneyIcon}
          to={routeGCPSpendByProjectsWPEngine.slice(1)}
          text="Cost"
        >
          <SidebarSubmenu title="Cost Insights">
            {sidebarSubmenuIFrames(iFrameMenu.COST)}
            <SidebarSubmenuItem
              icon={LibraryBooks}
              to="https://wpengine.atlassian.net/wiki/spaces/PE/pages/3257204768/How+to+review+Google+(GCP)+spend"
              title="How to review Google (GCP) spend"
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem
          icon={GoldenSignalsIcon}
          to="product-dashboard"
          text="Golden Signals"
        >
          <SidebarSubmenu title="Golden Signals">
            {sidebarSubmenuIFrames(iFrameMenu.GOLDEN_SIGNALS)}
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem
          icon={ArchitectureIcon}
          to="tech-radar"
          text="Architecture"
        >
          <SidebarSubmenu title="Architecture">
            <SidebarSubmenuItem
              icon={LibraryBooks}
              to="/docs/default/domain/architecture/glossaries/"
              title="Glossaries"
              subtitle="Terms, ubiquitous language"
            />
            <SidebarSubmenuItem
              icon={MacroArchitectureIcon}
              to="/docs/default/domain/architecture/Macro_Architecture/"
              title="Macro-architecture"
              subtitle="Navigate to Core Docs"
            />
            <SidebarSubmenuItem
              icon={PatternsIcon}
              to="/docs/default/domain/architecture/Patterns/"
              title="Patterns"
            />
            <SidebarSubmenuItem
              icon={StandardsIcon}
              to="/docs/default/domain/architecture/Service_Naming_Standards/"
              title="Standards"
              subtitle="P&E Standards"
            />
            <SidebarDivider />
            <SidebarGroup label="Advice">
              <SidebarSubmenuItem
                icon={AdrIcon}
                to="/docs/default/domain/architecture/Documenting_Architectural_Decisions/"
                title="ADRs"
                subtitle="Documenting Decisions"
              />
              <SidebarSubmenuItem
                icon={MapIcon}
                to="tech-radar"
                title="P&E Tech Radar"
                subtitle="P&E Recommendations"
              />
              <SidebarSubmenuItem
                icon={MapIcon}
                to="portal-tech-radar"
                title="User Portal Radar"
                subtitle="Portal-Specific Technology"
              />
              <SidebarSubmenuItem
                icon={ArchitecturePrinciplesIcon}
                to="/docs/default/domain/architecture/Architecture_Principles/"
                title="Arch Principles"
                subtitle="Test Your Design"
              />
            </SidebarGroup>
          </SidebarSubmenu>
        </SidebarItem>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
