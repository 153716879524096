import { useEntity } from '@backstage/plugin-catalog-react';
import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { IFrameContent } from '../IFrameContent';

export interface CloudBuildContentProps {}

const GITHUB_PROJECT_SLUG_ANNOTATION = 'github.com/project-slug';

export const isCloudbuildGoldenSignalsAvailable = (
  entity: Entity | undefined,
) =>
  Boolean(
    entity?.metadata.annotations?.[GITHUB_PROJECT_SLUG_ANNOTATION] &&
      entity?.metadata.annotations?.['google.com/cloudbuild-project-slug'],
  );
// We could also check for entity.metadata.annotation google.com/cloudbuild-project-slug when that is widely used.

export function EntityCloudbuildGoldenSignalsContent(
  _props: CloudBuildContentProps,
) {
  const { entity } = useEntity();
  let repo: string =
    entity?.metadata?.annotations?.[GITHUB_PROJECT_SLUG_ANNOTATION] || '';
  if (repo.startsWith('wpengine/')) {
    repo = repo.replace('wpengine/', '');
  }
  // Ref: https://developers.google.com/looker-studio/connector/data-source-parameters#set_url_parameters
  const lookerStudioParams = encodeURIComponent(`{"repository":"${repo}"}`);
  // fromRef: https://lookerstudio.google.com/u/0/reporting/f939fbf3-20f8-4b17-889f-6bf83c24a4d2/page/ViGnD/edit
  const iframeURL = `https://lookerstudio.google.com/embed/u/0/reporting/f939fbf3-20f8-4b17-889f-6bf83c24a4d2/page/ViGnD?params=${lookerStudioParams}`;

  return (
    <IFrameContent
      {...{
        iframe: {
          src: iframeURL,
        },
        title: 'Deploys by Cloud Build',
        description:
          'Click the link in the lower right to access the dashboard in Looker Studio.',
      }}
    />
  );
}
