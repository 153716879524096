// Ref: https://github.com/RoadieHQ/roadie-backstage-plugins/blob/main/plugins/frontend/backstage-plugin-iframe/src/components/ErrorComponent.tsx#L1-L13
import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export const ErrorComponent = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <Grid container>
      <Grid item xs={8}>
        <Typography>{errorMessage}</Typography>
      </Grid>
    </Grid>
  );
};
