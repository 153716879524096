/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// We are wrapping the plugin to override styling

import React from 'react';
import { Paper, } from '@material-ui/core';
import { UnifiedThemeProvider, themes, } from '@backstage/theme';
import { grpcDocsApiWidget } from '@backstage/plugin-api-docs-module-protoc-gen-doc';

/**
 * @public
 */
export const wpeGrpcDocsApiWidget = {
  type: grpcDocsApiWidget.type,
  title: grpcDocsApiWidget.title,
  component: (definition: string) => (
    /* There is a problem where grpc-docs overrides styling with a very specific color here:
     * https://github.com/gendocu-com/grpc-docs/blob/b431901b93e8b4636179df4b42d0f0665d69b2fa/src/style/default/sections/APIReference/TypeTable.tsx#L315
     * using &:nth-child(odd). With the dark mode pallette, this makes these columns in the table unreadable since the text is white 
     * but the background is virtually white. Here, we force the light theme on this widget to avoid the problem.
     */
    <UnifiedThemeProvider theme={themes.light}>
      <Paper>
        {grpcDocsApiWidget.component(definition)}
      </Paper>
    </UnifiedThemeProvider>
  ),
};
