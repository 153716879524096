import {
  RadarRing,
  RadarQuadrant,
  TechRadarLoaderResponse,
  TechRadarApi,
} from '@backstage-community/plugin-tech-radar';
import {wpeTechRadarEntries} from './wpeTechRadarEntries';
import {portalTechRadarEntries} from './portalTechRadarEntries'

const rings = new Array<RadarRing>();
rings.push({ id: 'Required', name: 'Required', color: '#0076dc' });
rings.push({ id: 'Adopt', name: 'Adopt', color: '#35872f' });
rings.push({ id: 'Evaluate', name: 'Evaluate', color: '#ff6119' });
rings.push({ id: 'Hold', name: 'Hold', color: '#d21b46' });
rings.push({ id: 'Prohibited', name: 'Prohibited', color: '#991433' });

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'Tools', name: 'Tools' });
quadrants.push({ id: 'Platforms', name: 'Platforms' });
quadrants.push({ id: 'Techniques', name: 'Techniques' });
quadrants.push({ id: 'languages-and-frameworks', name: 'Languages & Frameworks' });

export class WpeTechRadarApi implements TechRadarApi {
  async load(id: string | undefined) {
    const radar: TechRadarLoaderResponse = {
      entries: (id && id === 'user-portal-tech-radar') ? portalTechRadarEntries: wpeTechRadarEntries,
      quadrants,
      rings,
    };

    return radar;
  }
}